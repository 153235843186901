<template>
  <div class="animated fadeIn">
    <b-overlay
      :show="isLoading"
      :opacity="1"
      bg-color="white"
      spinner-variant="secondary"
      rounded="sm"
      z-index="1000"
    >
      <b-card>
        <b-row align-h="around">
          <b-col cols="4">
            <inline-select
              id="source-user-top"
              :value="data.source_user"
              :label="sourceUser.label"
              :options="sourceUser.options"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              @changed="onChangeSourceUser"
              :required="true"
            />
          </b-col>
          <b-col cols="4">
            <inline-select
              id="target-user-top"
              :value="data.target_user"
              :label="targetUser.label"
              :options="targetUser.options"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              @changed="onChangeTargetUser"
              :required="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <span class="m-3">Select items to assign to target user</span>
          <b-col cols="12">
            <b-table
              id="migration-table"
              :fields="entitiesTable.fields"
              :items="entitiesTable.items"
              responsive="sm"
              selectable
              select-mode="multi"
              @row-selected="onRowSelected"
              selected-variant="info"
              @row-clicked="onRowClick"
            >
              <template #cell(selected)="row">
                {{ row.selected }}
                <b-form-checkbox
                  :id="`${row.item.id}-checkbox-selected`"
                  v-model="row.rowSelected"
                  :disabled="true"
                />
              </template>

              <template #cell(amount)="row">
                <b-spinner
                  v-if="entitiesTable.isStatisticsLoading"
                  small
                  type="grow"
                />
                <span v-if="!entitiesTable.isStatisticsLoading">{{
                  row.item.amount
                }}</span>
              </template>

              <template #cell(entity)="row">
                {{ row.item.entity }}
              </template>

              <template #cell(description)="row">
                {{ row.item.description }}
              </template>

              <template #cell(target_user)="row">
                <inline-select
                  :ref="`${row.item.id}-target-user-inline`"
                  label="Target user"
                  :id="`${row.item.id}-target-user-inline`"
                  :key="`${row.item.id}-target-user-inline`"
                  :width="20"
                  :value="row.item.target_user"
                  :hide-label="true"
                  :options="userList"
                  :allow-empty="true"
                  :multiple="false"
                  :mode="$constants.FORM_MODE.EDIT"
                  :required="row.item.selected"
                  @changed="onChangeTargetUserInline(...arguments, row)"
                />
              </template>

              <template #cell(status)="row">
                <b-spinner v-if="row.item.isLoading" small type="grow" />
                <span v-if="!row.item.isLoading">{{ row.item.status }}</span>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <hr />
        <form-submission-actions
          :mode="$constants.FORM_MODE.READONLY"
          @back="$router.push($store.getters['router/previousRoute'])"
          @custom-click="
            name => {
              this[name]();
            }
          "
          :custom-buttons="[
            {
              text: 'Apply',
              icon: 'play',
              loading: false,
              visible: true,
              disabled: !readyToStart,
              method: 'onApply',
              variant: 'outline-dark'
            }
          ]"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import InlineSelect from "@/components/InlineSelectMultiple";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import FormSubmissionActions from "@/components/FormSubmissionActions";

export default {
  props: {
    userId: {
      type: String,
      default: ""
    }
  },
  name: "MigrationManager",
  components: {
    InlineSelect,
    FormSubmissionActions
  },
  data: function() {
    return {
      isLoading: false,
      userList: [],
      sourceUser: {
        id: "select:source_user",
        label: "Source user",
        changed: false,
        readonly: false,
        options: []
      },
      targetUser: {
        id: "select:target_user",
        label: "Target user",
        changed: false,
        readonly: false,
        options: []
      },
      entitiesTable: {
        isStatisticsLoading: false,
        fields: [
          {
            key: "selected",
            label: "#"
          },
          {
            key: "entity",
            label: "Entity"
          },
          {
            key: "amount",
            label: "Amount"
          },
          {
            key: "description",
            label: "Description"
          },
          {
            key: "target_user",
            label: "Target user"
          },
          {
            key: "status",
            label: "Status"
          }
        ],
        items: [
          {
            id: 0,
            selected: false,
            api_url: "accounts",
            entity: "Accounts",
            amount: 0,
            target_user: {},
            description: "Accounts assigned to the user",
            status: "",
            isLoading: false
          },
          {
            id: 1,
            selected: false,
            api_url: "contacts",
            entity: "Contacts",
            amount: 0,
            target_user: {},
            description: "Contacts assigned to the user",
            status: "",
            isLoading: false
          },
          /*
                    {
                        id: 2,
                        selected: false,
                        api_url: 'activities',
                        entity: 'Activities',
                        amount: 0,
                        target_user: {},
                        description: 'Activities assigned to the user',
                        status: '',
                        isLoading: false,

                    },
*/
          {
            id: 3,
            selected: false,
            api_url: "sample-requests",
            entity: "Sample requests",
            amount: 0,
            target_user: {},
            description: "Not sent sample requests",
            status: "",
            isLoading: false
          },
          {
            id: 4,
            selected: false,
            api_url: "barrels",
            entity: "Barrels",
            amount: 0,
            target_user: {},
            description: "Selected barrels related to not sent sample requests",
            status: "",
            isLoading: false
          },
          {
            id: 5,
            selected: false,
            api_url: "custom-filters",
            entity: "Custom filters",
            amount: 0,
            target_user: {},
            description: "Custom filters created by the user",
            status: "",
            isLoading: false
          },
          {
            id: 6,
            selected: false,
            api_url: "linked-custom-filters",
            entity: "Linked custom filters",
            amount: 0,
            target_user: {},
            description: "Custom filters linked to the user",
            status: "",
            isLoading: false
          },

          {
            id: 7,
            selected: false,
            api_url: "territories",
            entity: "Territories",
            amount: 0,
            target_user: {},
            description: "Territories assigned to the user",
            status: "",
            isLoading: false
          },

          {
            id: 8,
            selected: false,
            api_url: "projects",
            entity: "Projects",
            amount: 0,
            target_user: {},
            description: "Projects created by the user",
            status: "",
            isLoading: false
          },
          {
            id: 9,
            selected: false,
            api_url: "tasks",
            entity: "Tasks",
            amount: 0,
            target_user: {},
            description:
              "Not completed tasks created by the user or assigned to the user",
            status: "",
            isLoading: false
          },
          {
            id: 10,
            selected: false,
            api_url: "report-categories",
            entity: "Report categories",
            amount: 0,
            target_user: {},
            description:
              "Report categories in which the user is a reviewer or a responsible",
            status: "",
            isLoading: false
          }
        ]
      },
      data: {
        source_user: {
          id: "",
          label: ""
        },
        target_user: {
          id: "",
          label: ""
        }
      }
    };
  },
  computed: {
    readyToStart() {
      let validEntities = this.selectedEntities.filter(e => {
        let valid = this.$refs[`${e.id}-target-user-inline`].test();

        //console.log('readyToStart',e.id, valid)

        return valid;
      }).length;

      //console.log('valid count',validEntities)

      return validEntities !== 0 && this.data.source_user.id;
    },
    selectedEntities() {
      return this.entitiesTable.items.filter(i => i.selected);
    }
  },
  mounted() {
    let self = this;

    this.loadDictionaries().then(() => {
      if (self.userId) {
        self.data.source_user = self.userList.find(u => u.id === this.userId);

        this.fetchUserStats(self.userId);
      }
    });
  },
  methods: {
    async loadDictionaries() {
      const response = await this.$api.get("users/all");

      this.sourceUser.options = response.map(u => ({
        id: u.id,
        label: u.full_name
      }));

      this.targetUser.options = response.map(u => ({
        id: u.id,
        label: u.full_name
      }));

      this.userList = response.map(u => ({
        id: u.id,
        label: u.full_name
      }));
    },
    onRowClick(row) {
      row.selected = !row.selected;
    },
    onRowSelected() {
      // this.selected = items
    },
    onChangeSourceUser(id, value) {
      if (!value.id) return;

      this.data.source_user = value;

      this.fetchUserStats(value.id);

      this.resetStatuses();
    },
    resetStatuses() {
      for (let i = 0; i < this.entitiesTable.items.length; i++) {
        this.entitiesTable.items[i].status = "";
      }
    },
    onChangeTargetUser(id, value) {
      if (!value.id) return;

      for (let i = 0; i < this.entitiesTable.items.length; i++) {
        this.entitiesTable.items[i].target_user = value;
      }
    },

    onChangeTargetUserInline(id, value, row) {
      row.item.target_user = value;
      row.item.status = "";
    },

    fetchUserStats(user_id) {
      this.entitiesTable.isStatisticsLoading = true;

      this.$api.get(`user/${user_id}/statistics`).then(response => {
        this.entitiesTable.isStatisticsLoading = false;

        this.entitiesTable.items.find(i => i.entity === "Accounts").amount =
          response.accounts;
        this.entitiesTable.items.find(i => i.entity === "Contacts").amount =
          response.contacts;
        //this.entitiesTable.items.find(i => i.entity === 'Activities').amount = response.activities
        this.entitiesTable.items.find(
          i => i.entity === "Sample requests"
        ).amount = response.sample_requests;
        this.entitiesTable.items.find(i => i.entity === "Barrels").amount =
          response.barrels;

        this.entitiesTable.items.find(
          i => i.entity === "Custom filters"
        ).amount = response.custom_filters;

        this.entitiesTable.items.find(
          i => i.entity === "Linked custom filters"
        ).amount = response.linked_custom_filters;

        this.entitiesTable.items.find(i => i.entity === "Territories").amount =
          response.territories;
        this.entitiesTable.items.find(i => i.entity === "Projects").amount =
          response.projects;
        this.entitiesTable.items.find(i => i.entity === "Tasks").amount =
          response.tasks;
        this.entitiesTable.items.find(
          i => i.entity === "Report categories"
        ).amount = response.report_categories;
      });
    },
    async onApply() {
      let entities = this.selectedEntities
        .map(e => "'" + e.entity + "'")
        .join(", ");

      let confirm = await this.$form.showConfirmation(
        `${entities} will be reassigned. Do you want to proceed?`
      );

      if (!confirm) return;

      const self = this;

      let p = [];

      for (let i = 0; i < this.selectedEntities.length; i++) {
        let item = this.selectedEntities[i];

        let payload = {
          target_user_id: item.target_user.id
        };

        item.isLoading = true;

        const m = function(item, self) {
          //console.log(item.api_url)

          return self.$api
            .post(
              `user/${self.data.source_user.id}/migrate/${item.api_url}`,
              payload
            )
            .then(response => {
              item.isLoading = false;

              item.status = response.message;
            })
            .catch(error => {
              console.log(error);

              item.isLoading = false;

              item.status = error.message;
            });
        };

        p.push(m(item, self));
      }

      const user_id = this.data.source_user.id;

      Promise.all(p).then(() => {
        //console.log('Promise all completed')

        self.fetchUserStats(user_id);
      });
    }
  },
  watch: {}
};
</script>

<style>
#migration-table td {
  vertical-align: middle !important;
}

#migration-table {
  cursor: pointer;
}
</style>
