var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 1,
      "bg-color": "white",
      "spinner-variant": "secondary",
      "rounded": "sm",
      "z-index": "1000"
    }
  }, [_c('b-card', [_c('b-row', {
    attrs: {
      "align-h": "around"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": "source-user-top",
      "value": _vm.data.source_user,
      "label": _vm.sourceUser.label,
      "options": _vm.sourceUser.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onChangeSourceUser
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": "target-user-top",
      "value": _vm.data.target_user,
      "label": _vm.targetUser.label,
      "options": _vm.targetUser.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onChangeTargetUser
    }
  })], 1)], 1), _c('b-row', [_c('span', {
    staticClass: "m-3"
  }, [_vm._v("Select items to assign to target user")]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "id": "migration-table",
      "fields": _vm.entitiesTable.fields,
      "items": _vm.entitiesTable.items,
      "responsive": "sm",
      "selectable": "",
      "select-mode": "multi",
      "selected-variant": "info"
    },
    on: {
      "row-selected": _vm.onRowSelected,
      "row-clicked": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "cell(selected)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.selected) + " "), _c('b-form-checkbox', {
          attrs: {
            "id": "".concat(row.item.id, "-checkbox-selected"),
            "disabled": true
          },
          model: {
            value: row.rowSelected,
            callback: function callback($$v) {
              _vm.$set(row, "rowSelected", $$v);
            },
            expression: "row.rowSelected"
          }
        })];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(row) {
        return [_vm.entitiesTable.isStatisticsLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !_vm.entitiesTable.isStatisticsLoading ? _c('span', [_vm._v(_vm._s(row.item.amount))]) : _vm._e()];
      }
    }, {
      key: "cell(entity)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.entity) + " ")];
      }
    }, {
      key: "cell(description)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.description) + " ")];
      }
    }, {
      key: "cell(target_user)",
      fn: function fn(row) {
        return [_c('inline-select', {
          key: "".concat(row.item.id, "-target-user-inline"),
          ref: "".concat(row.item.id, "-target-user-inline"),
          attrs: {
            "label": "Target user",
            "id": "".concat(row.item.id, "-target-user-inline"),
            "width": 20,
            "value": row.item.target_user,
            "hide-label": true,
            "options": _vm.userList,
            "allow-empty": true,
            "multiple": false,
            "mode": _vm.$constants.FORM_MODE.EDIT,
            "required": row.item.selected
          },
          on: {
            "changed": function changed($event) {
              var _vm2;
              return (_vm2 = _vm).onChangeTargetUserInline.apply(_vm2, Array.prototype.slice.call(arguments).concat([row]));
            }
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function fn(row) {
        return [row.item.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !row.item.isLoading ? _c('span', [_vm._v(_vm._s(row.item.status))]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.$constants.FORM_MODE.READONLY,
      "custom-buttons": [{
        text: 'Apply',
        icon: 'play',
        loading: false,
        visible: true,
        disabled: !_vm.readyToStart,
        method: 'onApply',
        variant: 'outline-dark'
      }]
    },
    on: {
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      },
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }